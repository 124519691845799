import React from "react";
import Timer from "components/organisms/time";
//import PreIntro from "components/organisms/pre-intro";
import Intro from "components/organisms/intro";
import Place from "components/organisms/place";
import Party from "components/organisms/party";
import Bank from "components/organisms/bank";
import Contact from "components/organisms/contact";
import Footer from "components/organisms/footer";
import * as S from "./styled";

const Home = () => {
  return (
    <S.Container>
      <Timer time={new Date("2025-06-07T12:30:00")} />
      <Intro />
      <Place />
      <Party />
      <Bank />
      <Contact />
      <Footer />
    </S.Container>
  );
};

export default Home;
