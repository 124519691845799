export default {
  translation: {
    "Welcome to React": "Welcome to React and react-i18next",
    día: "día",
    mes: "mes",
    año: "año",
    am: "am",
    pm: "pm",
    hora: "hora",
    min: "min",
    días: "días",
    horas: "horas",
    seg: "seg",
    "destination time": "Fecha destino",
    "present time": "Fecha Presente",
    countdown: "Cuenta atrás",
    Celebracion: "Celebracion",
    "Hagamos de este día un momento único":
      "Hagamos de este día un momento único",
    Contacto: "Contacto",
    footer: {
      text1:
        "¡Por cierto! Como ya habrás notado, en ​esta web hay <strong>referencias a películas, ​series y videojuegos.</strong> Hemos puesto un <strong>​total de 19</strong>, pero <strong>si aciertas al menos 16 ​participarás en un sorteo</strong> para recibir ​un regalo sorpresa el día de nuestra ​celebración.",
      text2:
        "Puedes enviarnos la <strong>solución a ​cualquiera de los dos por WhatsApp</strong> ​hasta el 1 de mayo. ¡Muchas suerte!",
      bottom: "¡Te esperamos!",
    },
  },
};
