import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Head = styled.div`
  position: relative;
  bottom: 0px;
  width: 47px;
  height: 20px;
  border-radius: 100%;
  background-color: red;
  position: relative;
  box-shadow: 0px 1px #c80808, 0px 2px #c80808, 0px 3px #c80808, 0px 4px #c80808,
    0px 5px #c80808;
  z-index: 2;
  &:after {
    content: "";
    position: absolute;
    top: -31px;
    left: 50%;
    transform: translateX(-50%);
    width: 35px;
    height: 20px;
    border-radius: 100%;
    background-color: red;
    box-shadow: 0px 1px #c80808, 0px 2px #c80808, 0px 3px #c80808,
      0px 4px #c80808, 0px 5px #c80808;
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 31px;
    border-radius: 4px;
    background-color: #b20c0c;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const Bottom = styled.div`
  width: 6px;
  height: 50px;
  background-color: #a0a0a0;
  position: absolute;
  left: calc(50% - 3px);
  top: -4px;
  border-right: solid 1px #ffffff;
  z-index: 1;
  border-radius: 28%;
`;
