import styled, { css } from "styled-components";

const buttonStyle = css`
  display: flex;
  align-items: center;
`;

export const ContainerLink = styled.a`
  ${buttonStyle}
`;
