import styled from "styled-components";

export const Container = styled.div`
  display: block;
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const DianaVaro = styled.img`
  position: absolute;
  top: 33%;
  left: 22%;
  width: 55%;
`;

export const Newspapper = styled.img``;
