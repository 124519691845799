import React, { useRef } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as S from "./styled";

const BankAccount = ({ children, className }) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const handleCopy = () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(ref.current.innerHTML);
      toast(
        <S.ToastMessage>
          <p>{t("Copiado en portapapeles")}</p>
        </S.ToastMessage>
      );
    }
  };
  return (
    <S.Container className={className}>
      <p ref={ref} onClick={handleCopy}>
        {children}
      </p>
      {navigator?.clipboard?.writeText && <MdOutlineContentCopy />}
    </S.Container>
  );
};

export default BankAccount;
