import React from "react";
import * as S from "./styled";

const Button = ({ children, className, href, ...rest }) => {
  if (href) {
    return (
      <S.ContainerLink className={className} href={href} {...rest}>
        {children}
      </S.ContainerLink>
    );
  }

  return (
    <S.Container className={className} {...rest}>
      {children}
    </S.Container>
  );
};

export default Button;
