import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: grid;
  gap: 10px;
  color: white;
  background-color: #282828;
  box-shadow: 3px 3px 3px #00000045;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "header header header header"
    "vinyl player player player"
    "footer player player player";
  transition: all 0.3s;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    && {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "header header header header"
        ". vinyl vinyl ."
        "footer footer footer footer"
        "player player player player";
    }
  }
  &:hover {
    box-shadow: 6px 6px 6px #00000045;
    transform: translate(-1px, -1px);
  }
  &:hover .vinyl {
    transform: rotate(2deg);
  }
  padding: 20px;
  &:hover .disc {
    transform: translate(-50%, -110px) rotate(0deg);
  }
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    & .disc {
      transform: translate(-50%, -110px) rotate(0deg);
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: url("images/pulp-fiction.jpg");
    opacity: 0.3;
    z-index: 3;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 80% center;
    pointer-events: none;
  }
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "header header header header"
      ". vinyl vinyl ."
      "footer footer footer footer"
      "player player player player";
    &:before {
      background-size: 196px;
      background-position: right bottom;
    }
  }
`;

export const Header = styled.div`
  grid-area: header;
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    margin-bottom: 150px;
  }
`;

export const Text = styled.p`
  z-index: 2;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;
export const Subtext = styled.p`
  z-index: 2;
  text-align: center;
`;

export const Footer = styled.div`
  grid-area: footer;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const Vinyl = styled.div`
  position: relative;
  transition: all 0.3s;
  width: 200px;
  height: 200px;
  grid-area: vinyl;
  z-index: 2;
`;

export const Disc = styled.div`
  background-color: grey;
  width: 185px;
  height: 185px;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%) rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  box-shadow: -2px -2px 2px #00000045;
  z-index: 1;
  background-image: url(images/vaultboy.png);
  background-size: 70% auto;
  background-position: center;
  background-repeat: no-repeat;
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #282828;
    border-radius: 100%;
    border: solid 10px #d4cf38;
  }
  &:before {
    position: absolute;
    content: "";
    width: 150px;
    height: 150px;
    background-color: transparent;
    border-radius: 100%;
    border: solid 1px #00000045;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-area: player;
  z-index: 2;
`;

export const DiscBox = styled.div`
  position: relative;
  background-color: white;
  width: 200px;
  height: 200px;
  z-index: 2;
  box-shadow: 2px 2px 4px #b0aa8d;
  background: rgb(212, 211, 41);
  background: radial-gradient(
    circle,
    rgba(212, 211, 41, 1) 34%,
    rgba(225, 127, 246, 1) 73%
  );
`;

export const DiscImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FooterImg = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
