import React, { useState, useEffect } from "react";
import * as S from "./styled";
import { useTranslation } from "react-i18next";
import Timer from "components/atoms/timer";
import { intervalToDuration, differenceInDays } from "date-fns";
import PreIntro from "components/organisms/pre-intro";

const Time = ({ className, time }) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown({
        totalDays: differenceInDays(time, new Date()),
        ...intervalToDuration({ start: new Date(), end: time }),
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const { t } = useTranslation();
  return (
    <S.Container className={className}>
      <S.Background src="images/delorean.jpg"></S.Background>
      <S.ContainerTimer>
        <S.Wrapper>
          <PreIntro />
          <S.Title>
            <S.Part1>
              <S.Text1>{t("EMPIEZA")}</S.Text1>
              <S.Arrow1>{"<"}</S.Arrow1>
            </S.Part1>
            <S.Part2>
              <p>
                <S.Text2>{t("la cuenta")}</S.Text2>
              </p>
              <p>
                <S.Text3>{t("atras")}</S.Text3>
              </p>
            </S.Part2>
            <S.Part>
              <S.Arrow2>{"["}</S.Arrow2>
              <S.Number>{"{"}</S.Number>
            </S.Part>
          </S.Title>
          <Timer
            date={time}
            footerText={t("destination time")}
            textColor={"red"}
          />
          <Timer
            date={new Date()}
            footerText={t("present time")}
            textColor={"#37cc37"}
          />
          {countdown && (
            <Timer
              countdown={countdown}
              footerText={t("countdown")}
              textColor={"yellow"}
            />
          )}
        </S.Wrapper>
      </S.ContainerTimer>
    </S.Container>
  );
};

export default Time;
