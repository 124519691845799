import styled from "styled-components";
import ContainerComp from "components/atoms/container";

export const Container = styled.div`
  align-content: center;
  font-family: sans-serif;
  color: white;
  background-size: cover;
  background-position: center bottom;
  min-height: 500px;
`;

export const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 5;
  padding: 10px;
`;

export const Wrapper = styled(ContainerComp)`
  && > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
`;

export const ElementContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 40px;
  font-family: sans-serif;
`;

export const Number = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  padding: 5px;
`;

export const Logo = styled.div`
  display: inline-block;
  color: white;
`;

export const Elem = styled.div`
  background-color: #00800066;
  font-size: 48px;
  color: white;
  position: relative;
  padding: 21px 17px;
  border: solid 1px ${({ theme }) => theme.colors?.white};
  box-shadow: 1px 1px 1px #00000042;
  margin-right: 5px;
`;

export const Diana = styled.div``;

export const Varo = styled.div`
  padding-left: 81px;
`;

export const Name = styled.div`
  text-shadow: 1px 1px 1px #00000042;
`;

export const Content = styled.div``;

export const Title = styled.h2`
  font-size: 60px;
  font-family: "Arial";
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  font-size: 30px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
