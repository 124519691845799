import React from "react";
import * as S from "./styled";

const Light = ({ className, text, color }) => (
  <S.Container className={className}>
    <S.Light color={color} />
    <S.Text>{text}</S.Text>
  </S.Container>
);

export default Light;
