import styled, { css, keyframes } from "styled-components";
const animationBackground = keyframes`
  0% {
    filter: blur(8px);
  -webkit-filter: blur(8px);
  }
  50% {
   filter: blur(1px);
  -webkit-filter: blur(3px);
  }
  75% {
   filter: blur(8px);
  -webkit-filter: blur(3px);
  }

  75% {
   filter: blur(3px);
  -webkit-filter: blur(2px);
  }

  100% {
   filter: blur(8px);
  -webkit-filter: blur(8px);
  }
`;
const gradientText1 = css`
  background: -webkit-linear-gradient(#d85116, #f7dc11);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const gradientText2 = css`
  background: -webkit-linear-gradient(#f7dc11, #d85116);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ContainerTimer = styled.div`
  max-width: 500px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 108px 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 1px 1px 11px #00000073;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 20px;
  font-family: BTTF;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 168px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 10px;
  }
`;

export const Arrow1 = styled.span`
  margin-left: 9px;
  ${gradientText1}
  font-size: 81px;
  letter-spacing: 20px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 56px;
  }
`;

export const Arrow2 = styled.span`
  font-size: 63px;
  margin-left: 9px;
  letter-spacing: 4px;
  ${gradientText2}
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 53px;
  }
`;

export const Text1 = styled.span`
  font-size: 53px;
  font-family: BTTF;
  ${gradientText1}
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 29px;
  }
`;

export const Text2 = styled.span`
  font-size: 23px;
  font-family: BTTF;
  ${gradientText2}
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 13px;
  }
`;

export const Text3 = styled.span`
  font-size: 55px;
  font-family: BTTF;
  ${gradientText2}
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 33px;
  }
`;

export const Part1 = styled.div`
  display: flex;
  align-items: center;
`;

export const Part2 = styled.div`
  display: grid;
  grid-template-columns: 141px auto;
  margin-top: -6px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    grid-template-columns: 74px auto;
  }
`;

export const Number = styled.span`
  font-size: 90px;
  ${gradientText2}
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 70px;
  }
`;

export const Part = styled.p`
  position: relative;
  text-align: right;
  align-self: end;
  top: -47px;
  right: -46px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    right: -12px;
    top: -32px;
  }
`;

export const Background = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation: ${animationBackground} infinite 3s linear;
  object-fit: cover;
  opacity: 0.6;
`;
