import React from "react";
import * as S from "./styled";

const Intro = ({ className }) => {
  return (
    <S.Container className={className}>
      <S.Wrapper>
        <S.Content>
          <S.Video
            width="600"
            controls={false}
            autoplay
            autoPlay
            muted
            loop
            playsInline
          >
            <source src="video-intro.webm" type="video/webm" />
            Tu navegador no soporta el formato de video.
          </S.Video>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default Intro;
