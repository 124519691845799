import styled from "styled-components";
import SliderComp from "components/organisms/slider";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #f1e8e1;
`;

export const Slider = styled(SliderComp)``;
