import styled from "styled-components";
import ContainerComp from "components/atoms/container";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Wrapper = styled(ContainerComp)`
  position: relative;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div``;
