import styled, { css, keyframes } from "styled-components";

const animationPage = keyframes`
  0% {
       
       transform: rotateY(0deg) skewY(0deg);
    }

    50% {
        transform: rotateY(90deg) skewY(-25deg);
    }

    100% {
        
         transform: rotateY(180deg) skewY(0deg);
    }
`;

const animationPageBack = keyframes`
  0% {
       transform: rotateY(180deg) skewY(0deg);
    }

    50% {
        transform: rotateY(90deg) skewY(-25deg);
    }

    100% {
        
         transform: rotateY(0deg) skewY(0deg);
    }
`;

const pageControler = css`
  position: absolute;
  width: 0%;
  height: 0%;
  z-index: 9999;
  cursor: pointer;
  border: 30px solid #c3bea3;
  transform: translate(0%, 0%) rotateZ(0deg) !important;
  cursor: pointer;
  transition: all 0.3s;
  border-left-color: #e6dfbcf7;
  border-top-color: #e6dfbcf7;
  box-shadow: -2px -2px 6px #b9b49a;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    display: none;
  }
  &:hover {
    border-width: 40px;
  }
`;

export const Container = styled.div`
  position: relative;
  height: 366px;
  width: 530px;
  min-width: 530px;
  transform: rotate3d(1, 1, 1, 0deg) scale(1);
  transition: all 0.3s;
  &:hover {
    transform: rotate3d(1, 1, 1, 8deg) scale(1.1);
  }
  @media (max-width: ${({ theme }) => theme.sizes.lg}px) {
    transform: rotate3d(1, 1, 1, 0deg) scale(1);
    &:hover {
      transform: rotate3d(1, 1, 1, 0deg) scale(1);
    }
  }
`;
export const Wrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
`;
export const Page = styled.div`
  width: ${({ lastPage }) => (lastPage ? "100%" : "calc(100% - 10px)")};
  height: 100%;
  left: 0px;
  top: 0px;
  position: relative;
  background-color: #e6dfbcf7;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
  &.remove-animation {
    height: auto;
    width: 100%;
    box-shadow: 3px 3px 3px #00000045;
    &:nth-child(odd) {
      margin-bottom: 20px;
    }
  }
  &:not(.remove-animation) {
    position: absolute;
    box-shadow: ${({ lastPage }) =>
      Array(lastPage ? 6 : 3)
        .fill()
        .map(
          (ite, index) =>
            `${index > 0 ? ", " : ""}${index}px ${index}px 0px ${
              lastPage ? "#886653f7" : "#e6dfbcf7"
            }`
        )};
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    z-index: ${({ count, page, selected }) => (selected ? page : count - page)};
    transition: z-index 0s 0.5s;
    animation: ${animationPageBack} 1s linear forwards;

    &:nth-child(even) > * {
      transform: scaleX(-1);
    }
    &.selected {
      animation: ${animationPage} 1s linear forwards;
    }
  }
`;

export const PrevPage = styled.div`
  bottom: 0px;
  left: 0px;
  ${pageControler}
`;

export const NextPage = styled.div`
  ${pageControler}
  bottom: 0px;
  right: 0px;
`;

export const BookImg = styled.img`
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LastPage = styled.div`
  background-color: #694633f7;
  position: relative;
  height: 100%;
  border: solid 4px #886653f7;
`;

export const Stiker1 = styled.img`
  position: absolute;
  top: 10px;
  left: 30px;
  width: 100px;
  height: auto;
  transform: rotateZ(-8deg);
`;

export const Stiker2 = styled.img`
  position: absolute;
  top: 154px;
  left: 169px;
  width: 100px;
  height: auto;
  transform: rotateZ(10deg);
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
