import React from "react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import i18n from "system/i18n";
import Home from "components/page/home";
import Layout from "components/system/layout";
import theme from "system/theme";
import "swiper/css";
import "swiper/css/pagination";
i18n();
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Home />
      </Layout>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
