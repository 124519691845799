import styled from "styled-components";
import LightComp from "components/atoms/light";

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

export const Light = styled(LightComp)``;
