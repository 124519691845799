import React from "react";
import * as S from "./styled";

const ElementText = ({ elem, elemNum, text }) => (
  <S.ElementContainer>
    <S.Elem>
      {elem}
      <S.Number>{elemNum}</S.Number>
    </S.Elem>
    <S.Name>{text}</S.Name>
  </S.ElementContainer>
);

const PreHeader = ({ className }) => {
  /*
  return (
    <S.Header>
      <S.Logo className={className}>
        <S.Diana>
          <ElementText elem="Di" elemNum={66} text="ana" />
        </S.Diana>
        <S.Varo>
          <ElementText elem="Ál" elemNum={13} text="varo" />
        </S.Varo>
      </S.Logo>
    </S.Header>
  );
  */

  return (
    <S.Container className={className}>
      <S.Wrapper>
        <S.Logo>
          <S.Diana>
            <ElementText elem="Di" elemNum={66} text="ana" />
          </S.Diana>
          <S.Varo>
            <ElementText elem="Ál" elemNum={13} text="varo" />
          </S.Varo>
        </S.Logo>
      </S.Wrapper>
    </S.Container>
  );
};

export default PreHeader;
