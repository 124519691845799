import React from "react";
import * as S from "./styled";

const Newspapper = ({ className }) => (
  <S.Container className={className}>
    <S.DianaVaro src="images/dianaVaro.gif" />
    <S.Newspapper src="images/newspaper.png" />
  </S.Container>
);
export default Newspapper;
