import styled from "styled-components";
import ContainerComp from "components/atoms/container";

export const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 50px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 500px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    flex-direction: column;
    height: auto;
  }
`;

export const Header = styled(ContainerComp)`
  font-size: 61px;
  text-align: center;
  font-family: serif;
  padding-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 40px;
  }
`;

export const Item = styled.div`
  position: relative;
  width: 50%;
  transition: all 0.3s;
  &:nth-child(odd) {
    & > img {
      clip-path: polygon(0 0, 100% 0%, calc(100% - 200px) 100%, 0% 100%);
      left: 0px;
      top: 0px;
    }
    & .contactContent {
      left: 0px;
      clip-path: polygon(0px 0%, 100% 0%, calc(100% - 200px) 100%, 0% 100%);
      align-items: start;
      justify-content: center;
      @media (max-width: ${({ theme }) => theme.sizes.md}px) {
        justify-content: start;
      }
    }
    @media (min-width: ${({ theme }) => theme.sizes.md}px) {
      &:hover {
        & .contactContent {
          width: 457px;
          clip-path: polygon(0px 0%, 100% 0%, calc(100% - 200px) 100%, 0% 100%);
        }
      }
    }
  }
  &:nth-child(even) {
    & > img {
      clip-path: polygon(200px 0%, 100% 0%, 100% 100%, 0% 100%);
      right: 0px;
      top: 0px;
    }
    & .contactContent {
      clip-path: polygon(200px 0%, 100% 0%, calc(100%) 100%, 0% 100%);
      align-items: end;
      right: 0px;
      justify-content: center;
      @media (max-width: ${({ theme }) => theme.sizes.md}px) {
        justify-content: end;
      }
    }
    @media (min-width: ${({ theme }) => theme.sizes.md}px) {
      &:hover {
        & .contactContent {
          width: 457px;
          clip-path: polygon(200px 0%, 100% 0%, calc(100%) 100%, 0% 100%);
        }
      }
    }
  }
  & > img {
    position: absolute;
    object-fit: cover;
    width: calc(100% + 90px);
    height: 100%;
  }
  @media (min-width: ${({ theme }) => theme.sizes.md}px) {
    &:hover {
      width: 80%;
      filter: none;
      img {
        filter: grayscale(100%);
      }
      & .contactContent {
        width: 567px;
        & .contactContentWrapper {
          opacity: 1;
          transition: all 0.2s 0.3s;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    && {
      width: 100%;
      height: 300px;
      & .contactContent {
        width: 75%;
        & .contactContentWrapper {
          opacity: 1;
          transition: all 0.2s 0.3s;
          font-size: 19px;
        }
      }
      && > img {
        clip-path: polygon(0 0, 100% 0%, calc(100%) 100%, 0% 100%);
        width: 100%;
      }
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 2;
  background-color: #e7cece;
  width: 120px;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    padding: 20px;
  }
`;

export const ContentHeader = styled.h3`
  font-family: serif;
  font-size: 40px;
  color: ${({ theme }) => theme.colors.black};
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 25px;
  }
`;

export const ContentWrapper = styled.div`
  opacity: 0;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.black};
`;

export const Background = styled.img`
  opacity: 0.1;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 0px;
  object-fit: contain;
`;

export const ImgIntro = styled.img`
  width: 57px;
  opacity: 0.8;
  margin-top: -3px;
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
`;
