import React from "react";
import * as S from "./styled";

const Picture = ({ className }) => (
  <S.Container className={className}>
    <S.Head />
    <S.Bottom />
  </S.Container>
);

export default Picture;
