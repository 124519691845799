import { useState, useEffect } from "react";
import theme from "system/theme";

const getScreenSize = (width) => {
  if (width >= theme.sizes.xl) return "xl";
  if (width >= theme.sizes.lg) return "lg";
  if (width >= theme.sizes.md) return "md";
  if (width >= theme.sizes.sm) return "sm";
  return "xs"; // Para pantallas más pequeñas que 360px
};

const useResponsive = () => {
  // Estado que mantiene el rango actual de pantalla
  const [screenSize, setScreenSize] = useState(() =>
    getScreenSize(window.innerWidth)
  );

  useEffect(() => {
    // Handler para actualizar el tamaño de pantalla
    const handleResize = () => {
      setScreenSize(getScreenSize(window.innerWidth));
    };

    // Agregar el listener al evento resize
    window.addEventListener("resize", handleResize);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export default useResponsive;
