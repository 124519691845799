import styled from "styled-components";
import NewspaperComp from "components/atoms/newspaper";
import Pin from "components/atoms/pin";
import ContainerComp from "components/atoms/container";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled(ContainerComp)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  & > div {
    display: flex;
    justify-content: center;
  }
`;

export const Newspaper = styled(NewspaperComp)``;

export const Content = styled.div`
  position: relative;
  width: 80%;
  height: 100%;
  object-fit: cover;
  transform: rotate(6deg);
  border: solid 38px #ffffff;
  box-shadow: 1px 1px 11px #00000073;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: -38px;
    left: 92%;
    transform: rotate(45deg);
    width: 200px;
    height: 50px;
    background-color: black;
    opacity: 0.1;
  }
  &:after {
    content: "";
    position: absolute;
    top: -38px;
    right: 92%;
    transform: rotate(-45deg);
    width: 200px;
    height: 50px;
    background-color: black;
    opacity: 0.1;
  }
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    position: relative;
    width: 100%;
    transform: rotate(0deg);
    border: solid 20px #ffffff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    &:before,
    &:after {
      display: none;
    }
    @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    border: solid 0px #ffffff;
  }
`;

export const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    width: 200%;
    min-width: 200%;
    max-width: 200%;
  }
`;

export const Pin1 = styled(Pin)`
  position: absolute;
  top: 3%;
  left: 50%;
  transform: rotate(25deg);
  z-index: 1;
`;
