import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 200px;
  height: 250px;
  object-fit: cover;
  padding: 10px;
  background-color: white;
  box-shadow: 2px 2px 5px #b0a88a;
  font-family: handmade;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    height: 20px;
    width: 60px;
    background-color: black;
    opacity: 0.1;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 141px;
`;

export const Footer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
