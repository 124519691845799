import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #636363;
  padding: 10px 20px;
  border-radius: 2px;
  width: 100%;
  justify-content: center;
  border: solid 1px #c3c3c3;
  gap: 10px;
  box-shadow: 3px 5px 10px #0b0e298f;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  gap: 10px;
  width: 100%;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    gap: 5px;
    min-height: 73px;
  }
`;

export const Item = styled.div`
  padding: 10px;
  border: solid 1px;
  font-size: 42px;
  background-color: black;
  color: ${({ textcolor }) => textcolor || "red"};
  border-color: white;
  font-family: Digital;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 20px;
    padding: 5px;
  }
`;

export const Year = styled.div``;

export const Month = styled.div``;

export const Day = styled.div``;

export const Time = styled.div``;

export const Label = styled.div`
  padding: 5px;
  color: ${({ theme }) => theme.colors?.white};
  background-color: #b22d2d;
  display: inline-block;
  margin: 5px;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 13px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 9px;
  }
`;

export const Content = styled(Item)``;

export const Dots = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: end;
  gap: 5px;
  ${({ nolabel }) => (nolabel ? "height: 59px" : "")};
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    gap: 3px;
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ color }) => color || "red"};
  opacity: ${({ selected }) => (selected ? 1 : 0.2)};
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    width: 6px;
    height: 6px;
  }
`;

export const AmPm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterText = styled.div`
  font-family: sans-serif;
  color: white;
  padding: 10px;
  background-color: black;
  font-size: 14px;
  text-transform: uppercase;
`;
