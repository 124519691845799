import React from "react";
import * as S from "./styled";

const Photo = ({ src, className, footer }) => (
  <S.Container className={className}>
    <S.Image src={src} />
    <S.Footer>{footer}</S.Footer>
  </S.Container>
);

export default Photo;
