import styled from "styled-components";
import ContainerComp from "components/atoms/container";

export const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  flex-direction: column;
  font-weight: lighter;
  background-color: #e7cece;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Wrapper = styled(ContainerComp)`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
`;

export const Content = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentButton = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
