import React from "react";
import { Trans } from "react-i18next";
import * as S from "./styled";

const Bank = ({ className }) => {
  return (
    <S.Container className={className}>
      <S.Wrapper size="md">
        <S.ContentText>
          <Trans i18nKey="footer.text1" components={{ strong: <strong /> }} />
        </S.ContentText>
        <img width={150} src={"/images/ojo-universo.png"} />
        <S.ContentText>
          <Trans i18nKey="footer.text2" components={{ strong: <strong /> }} />
        </S.ContentText>
      </S.Wrapper>
      <S.Bottom>
        <S.BottomText>
          <Trans i18nKey="footer.bottom" />
        </S.BottomText>
        <S.ImageBottom src="images/forrest-bump.png" />
      </S.Bottom>
    </S.Container>
  );
};

export default Bank;
