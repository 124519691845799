import styled, { css, keyframes } from "styled-components";
import Photo from "components/atoms/photo";
import LightList from "components/molecules/light-list";
import ContainerComp from "components/atoms/container";

const animation = keyframes`
  0% {
    transform: rotate(10deg);
  }
    50% {
    transform: rotate(17deg);
  }
    100% {
    transform: rotate(10deg);
  }
`;

const page = css`
  padding: 20px;
  font-family: handmade;
  font-size: 21px;
`;

export const Container = styled.div`
  background-color: #e7cece;
  padding: 0px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    padding: 20px 0px;
  }
`;

export const TitleImg = styled.img`
  width: 60px;
  height: 50px;
  object-fit: contain;
  transform: rotate(-17deg);
`;

export const Intro = styled.div`
  font-size: 30px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  & p {
    text-align: center;
    color: #787878;
  }
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Title = styled.div`
  font-size: 61px;
  flex-grow: 1;
  font-family: serif;
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 40px;
  }
`;

export const Tilde = styled.span`
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    background-image: url(images/totem.png);
    width: 46px;
    height: 46px;
    background-size: contain;
    display: block;
    background-repeat: no-repeat;
    position: absolute;
    right: -14px;
    top: -24px;
    opacity: 0.7;
    transform-origin: 50% 100%;
    animation: ${animation} 0.1s linear infinite;
    @media (max-width: ${({ theme }) => theme.sizes.md}px) {
      width: 37px;
      height: 37px;
      right: -12px;
      top: -31px;
    }
  }
`;

export const Container2 = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  position: relative;
  & > div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  & .leaflet-container {
    z-index: 1;
    mix-blend-mode: luminosity;
  }
`;

export const Wrapper = styled(ContainerComp)`
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const FooterImg = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: auto;
  z-index: 38;
`;

export const Book = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  z-index: 2;
  gap: 40px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    flex-direction: column;
    padding: 50px 0px;
  }
`;

export const Map = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 30px;
  transform: rotateZ(5deg);
  & > * {
    height: 295px;
    width: 100%;
    box-shadow: 2px 2px 5px #b0a88a;
  }
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    transform: rotateZ(0deg);
    padding: 0px;
  }
`;

export const ImagePalacio = styled(Photo)`
  position: absolute;
  top: 20px;
  left: 30px;
  transform: rotateZ(30deg);
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    position: relative;
    top: 0px;
    left: 0px;
    align-self: center;
  }
`;

export const ImageHotel = styled(Photo)`
  position: absolute;
  top: 103px;
  left: 270px;
  transform: rotateZ(-26deg);
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    position: relative;
    top: 0px;
    left: 0px;
  }
`;

export const Page1 = styled.div`
  ${page}
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  min-height: 368px;
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TitleList = styled.div`
  font-weight: 700;
  font-size: 31px;
  margin-bottom: 20px;
  color: #1212a6;
  text-align: left;
`;

export const List = styled.div`
  font-size: 21px;
  text-align: left;
  margin-bottom: 10px;
  & p {
    padding-bottom: 6px;
  }
`;

export const Transport = styled.div`
  position: relative;
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Hotel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  ul {
    list-style-type: circle;
    padding-left: 20px;
  }
  & li {
    margin: 10px 0px;
  }
`;

export const Kitt = styled.img`
  position: absolute;
  width: 100px;
  height: 100px;
  top: -8px;
  right: -2px;
  object-fit: contain;
  transform: rotateZ(41deg);
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    right: 27px;
    top: -107px;
  }
`;

export const Header = styled(LightList)`
  margin-bottom: 100px;
  font-size: 100px;
  color: white;
  font-family: "handmade";
  font-weight: 700;
`;

export const TheWay = styled.img`
  position: absolute;
  width: 83px;
  height: 81px;
  top: 240px;
  left: 172px;
  object-fit: contain;
  transform: rotateZ(-3deg);
`;

export const Sticker = styled.img`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 15px;
  right: 12px;
  object-fit: contain;
  transform: rotateZ(9deg);
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    right: 27px;
    top: -0px;
    transform: rotate(-22deg);
  }
`;

export const Sticker2 = styled.img`
  position: absolute;
  width: 70px;
  height: 70px;
  top: 15px;
  right: 15px;
  object-fit: contain;
  transform: rotateZ(9deg);
  @media (max-width: ${({ theme }) => theme.sizes.sm}px) {
    right: 27px;
    top: 20px;
  }
`;

export const Highlight = styled.span`
  background-color: orange;
`;

export const LinkContainer = styled.div`
  display: flex;
  gap: 10px;
`;
