import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import * as S from "./styled";

const Slider = ({ children, className }) => (
  <S.Container className={className + " swiper"}>
    {children && (
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {children?.map((item) => (
          <SwiperSlide class="swiper-slide" key={item}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    )}
  </S.Container>
);

export default Slider;
