export default {
  colors: {
    primary: "red",
    secondary: "blue",
    white: "white",
    black: "#4f4f4f",
    background: "#f4cbcb",
  },
  sizes: {
    xl: 1920,
    lg: 1280,
    md: 1090,
    sm: 560,
  },
};
