import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0% {
    transform: rotateX(-30deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(-30deg) rotateY(360deg);
  }
   
`;

const width = 85;
const height = width;

const Side = styled.div`
  display: table;
  position: absolute;
  text-align: center;
  color: white;
  width: ${width}px;
  height: ${height}px;
  transform-origin: center;
  background-image: url("images/crash-box-sprite.jpg");
`;

export const Container = styled.div`
  width: ${width}px;
  height: ${width}px;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: ${height / 2}px ${height / 2}px -${height / 2}px;
  transition: all 2s;
  transform: rotateX(-30deg) rotateY(0deg);
  animation: ${animation} 20s infinite linear;
`;
export const Front = styled(Side)`
  background-color: transparent;
  background-position: -2px 454px;
`;
export const Back = styled(Side)`
  background-color: blue;
  transform: rotateY(180deg) translateZ(${height}px);
  background-position: -2px 452px;
`;
export const Right = styled(Side)`
  transform: rotateY(90deg) translateZ(${height / 2}px)
    translateX(${height / 2}px);
  background-color: green;
  background-position: -2px 452px;
`;
export const Left = styled(Side)`
  transform: rotateY(-90deg) translateZ(${height / 2}px)
    translateX(${-height / 2}px);
  background-color: yellow;
  background-position: 0px 454px;
`;
export const Top = styled(Side)`
  background-color: pink;
  transform: rotateX(-90deg) translateZ(${-height / 2}px)
    translateY(${height / 2}px);
  background-position: -172px 537px;
`;
export const Bottom = styled(Side)`
  background-color: black;
  transform: rotateX(90deg) translateZ(${-height / 2}px)
    translateY(${-height / 2}px);
  background-position: -172px 537px;
`;
