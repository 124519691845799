import React from "react";
import * as S from "./styled";

const Button = ({ children, className, href, ...rest }) => {
  return (
    <S.ContainerLink className={className} href={href} {...rest}>
      {children}
    </S.ContainerLink>
  );
};

export default Button;
