import React from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowForward } from "react-icons/io";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Button from "components/atoms/button";
import { Icon } from "leaflet";
import Book from "components/molecules/book";
import useResponsive from "hooks/useResponsive";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Link from "components/atoms/link";
import * as S from "./styled";

const Page1 = () => (
  <S.Page1>
    <S.ImagePalacio
      src="images/palacio.jpg"
      footer={
        <>
          <p>Palacio de Silvela</p>
          <p>C. de Palacio Silvela, 1, 28300 Aranjuez-Madrid</p>
          <Link
            href="https://maps.app.goo.gl/j7kKitpm6pgzjz5F8"
            target="_blank"
            rel="noreferrer"
          >
            Ir a mapa <MdOutlineArrowForwardIos />
          </Link>
        </>
      }
    />
    <div></div>
    <S.Transport>
      <S.Kitt src="images/kitt.png" />
      <div>
        <S.TitleList>Autobús ida:</S.TitleList>
        <S.List>
          <div>
            <p>Madrid 11:00</p>
            ----------------------
            <p>Aranjuez 12:00</p>
          </div>
        </S.List>
      </div>
      <div>
        <S.TitleList>Autobús Vuelta:</S.TitleList>
        <S.List>
          <div>
            <p>Aranjuez 00:30</p>
            ----------------------
            <p>Madrid 1:30</p>
          </div>
        </S.List>
        <p>
          <S.Highlight>(Las horas son orientativas)</S.Highlight>
        </p>
      </div>
    </S.Transport>
  </S.Page1>
);

const Page3 = ({ image, imageFooter, content, title, sticker, sticker2 }) => {
  return (
    <S.Page1>
      <S.ImageHotel src={image} footer={imageFooter} />
      <S.Hotel>
        <S.TitleList>{title}</S.TitleList>
        {content}
      </S.Hotel>
      {sticker && <S.Sticker src={sticker} />}
      {sticker2 && <S.Sticker2 src={sticker2} />}
      <div></div>
    </S.Page1>
  );
};

const Map = ({ coords }) => {
  const marker = new Icon({
    iconUrl: "images/market.png",
    iconSize: [100, 100],
  });
  if (!coords) return null;
  return (
    <S.Map>
      <MapContainer
        center={coords}
        zoom={14}
        scrollWheelZoom={false}
        dragging={false}
        doubleClickZoom={false}
        boxZoom={false}
        touchZoom={false}
        tapHold={false}
        keyboard={false}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={coords} icon={marker}>
          <Popup autoPan>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </S.Map>
  );
};

const Place = ({ className }) => {
  const { t } = useTranslation();

  const size = useResponsive();
  const isMobile = size === "sx";

  return (
    <S.Container className={className}>
      <S.Wrapper size={isMobile ? "full" : "lg"}>
        <S.Book>
          <S.Intro>
            <S.Title>
              Celebraci<S.Tilde className="tilde">o</S.Tilde>n
            </S.Title>
            <div>
              <p>07.06.2025</p>
              <p>{t("Sábado 12:30h")}</p>
            </div>

            <Button
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSf7gE1-vPygimKDBnVPKOkXTagK133d0iUjpj4VzQAxFojRoQ/viewform"
            >
              {t("Confirmar Asistencia")}
            </Button>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSf7gE1-vPygimKDBnVPKOkXTagK133d0iUjpj4VzQAxFojRoQ/viewform"
            >
              <img src="images/this-is-the-way.png" width={187} />
            </a>
          </S.Intro>
          <Book>
            <Page1 />
            <Map coords={[40.035213795119674, -3.6105892979670045]} />
            <Page3
              title={"Hotel NH Collection"}
              image={"images/hotel.jpg"}
              sticker2={"images/bellaybestia.png"}
              imageFooter={
                <>
                  <p>Hotel NH Collection</p>
                  <p>Palacio de Aranjuez</p>
                  <Link
                    href="https://maps.app.goo.gl/AbDPa8D127sApoYq5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ir a mapa <MdOutlineArrowForwardIos />
                  </Link>
                </>
              }
              content={
                <>
                  <p>C. de San Antonio, 22, 28300 Aranjuez, Madrid</p>
                  <ul>
                    <li>
                      <p>
                        Es el más cercano a la finca donde se celebra la boda (1
                        min andando)
                      </p>
                    </li>
                  </ul>
                  <p>
                    <S.Highlight>Código promocional: ENELNH2024</S.Highlight>
                  </p>
                  <S.LinkContainer>
                    <Link href="https://www.nh-hotels.com/es/hotel/nh-collection-palacio-de-aranjuez?campid=8435708&gad_source=1&gclid=CjwKCAjw68K4BhAuEiwAylp3kmIhhgmPVPUgVac4v3niUX5DCqTUTvs1ufX_HCa7rqBeeyHY9x7h0hoCJ5YQAvD_BwE&gclsrc=aw.ds">
                      {t("Ir a la web")} <IoIosArrowForward />
                    </Link>
                  </S.LinkContainer>
                </>
              }
            />
            <Map coords={[40.0338664801561, -3.608662918357117]} />
            <Page3
              title={"EQUO Aranjuez"}
              image={"images/equo.jpg"}
              imageFooter={
                <>
                  <p>EQUO Aranjuez</p>
                  <Link
                    href="https://maps.app.goo.gl/MbmLKakfvV3N1GCa8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ir a mapa <MdOutlineArrowForwardIos />
                  </Link>
                </>
              }
              sticker={"images/rick&morty1.png"}
              content={
                <>
                  <p>
                    C. Del Capitán Angosto Gómez Castrillón, 145, Aranjuez, ES
                    28300
                  </p>
                  <p>
                    Para obtener el descuento{" "}
                    <S.Highlight>reserva por teléfono o email</S.Highlight>{" "}
                    indicando que vienes de nuestra boda en el{" "}
                    <S.Highlight>Palacio de Silvela</S.Highlight>
                  </p>
                  <a href="tel:913959811">tel: 913 95 9811</a>
                  <a href="mailto:reservas@equoaranjuez.es">
                    email: reservas@equoaranjuez.es
                  </a>
                </>
              }
            />
            <Map coords={[40.028058003840975, -3.602447887670095]} />
          </Book>
        </S.Book>
      </S.Wrapper>
    </S.Container>
  );
};

export default Place;
