import React from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import * as S from "./styled";

const Timer = ({ date, countdown, footerText, textColor }) => {
  const { t } = useTranslation();
  const isPM = !(date && parseInt(format(date, "hh")) <= 12);

  if (countdown) {
    return (
      <S.Container>
        <S.Wrapper>
          <S.Day>
            <S.Label>{t("días")}</S.Label>
            <S.Content textcolor={textColor}>
              {countdown.totalDays || "00"}
            </S.Content>
          </S.Day>
          <S.Month>
            <S.Label>{t("horas")}</S.Label>
            <S.Content textcolor={textColor}>
              {countdown.hours || "00"}
            </S.Content>
          </S.Month>
          <S.Year>
            <S.Label>{t("minutos")}</S.Label>
            <S.Content textcolor={textColor}>
              {countdown.minutes || "00"}
            </S.Content>
          </S.Year>
          <S.Dots nolabel>
            <S.Dot color={textColor} selected />
            <S.Dot color={textColor} selected />
          </S.Dots>
          <S.Time>
            <S.Label>{t("segundos")}</S.Label>
            <S.Content textcolor={textColor}>
              {countdown.seconds || "00"}
            </S.Content>
          </S.Time>
        </S.Wrapper>
        {footerText && <S.FooterText>{footerText}</S.FooterText>}
      </S.Container>
    );
  }
  return (
    <S.Container>
      <S.Wrapper>
        <S.Day>
          <S.Label>{t("día")}</S.Label>
          <S.Content textcolor={textColor}>{format(date, "dd")}</S.Content>
        </S.Day>
        <S.Month>
          <S.Label>{t("mes")}</S.Label>
          <S.Content textcolor={textColor}>{format(date, "MM")}</S.Content>
        </S.Month>
        <S.Year>
          <S.Label>{t("año")}</S.Label>
          <S.Content textcolor={textColor}>{format(date, "yyyy")}</S.Content>
        </S.Year>
        <S.Dots>
          <S.AmPm>
            <S.Label>{t("AM")}</S.Label>
            <S.Dot color={textColor} selected={!isPM} />
          </S.AmPm>
          <S.AmPm>
            <S.Label>{t("PM")}</S.Label>
            <S.Dot color={textColor} selected={isPM} />
          </S.AmPm>
        </S.Dots>
        <S.Time>
          <S.Label>{t("hora")}</S.Label>
          <S.Content textcolor={textColor}>{format(date, "hh")}</S.Content>
        </S.Time>
        <S.Dots nolabel>
          <S.Dot color={textColor} selected />
          <S.Dot color={textColor} selected />
        </S.Dots>
        <S.Time>
          <S.Label>{t("min")}</S.Label>
          <S.Content textcolor={textColor}>{format(date, "mm")}</S.Content>
        </S.Time>
      </S.Wrapper>
      {footerText && <S.FooterText>{footerText}</S.FooterText>}
    </S.Container>
  );
};

export default Timer;
