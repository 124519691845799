import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Light = styled.div`
  display: inline-block;
  position: relative;
  background-color: ${({ color }) => color};
  width: 11px;
  height: 27px;
  border-radius: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0px 0px 10px ${({ color }) => color};
  transition: all 0.3s;
  &:before {
    content: "";
    top: -22%;
    left: 50%;
    position: absolute;
    background-color: ${({ color }) => color};
    box-shadow: 0px 0px 10px ${({ color }) => color};
    border-radius: 100%;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    height: 110%;
    width: 128%;
    z-index: 2;
    transition: all 0.3s;
    border-top-right-radius: 57%;
    border-top-left-radius: 41%;
    transform: translateX(-50%);
  }
  &:after {
    content: "";
    top: -57%;
    left: 50%;
    position: absolute;
    background-color: #dcdcdc;
    border-radius: 12px;
    height: 57%;
    width: 135%;
    transform: translateX(-50%);
    z-index: 1;
    border: solid 1px #b4b2b2;
}
  }
`;

export const Text = styled.p`
  text-align: center;
  color: white;
  font-family: "handmade";
  font-size: 50px;
  margin-top: 10px;
  font-weight: bold;
`;
