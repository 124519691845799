import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  max-width: ${({ theme, size }) => {
    if (size === "full") {
      return "100vw";
    }
    return `${theme.sizes?.[size === "full" ? "100%" : size || "lg"]}px`;
  }};
  padding: 40px 0px;
  width: 100%;
`;
