import React from "react";
import * as S from "./styled";

const LightList = ({ className, text }) => {
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const arrayText = text.split("");
  return (
    <S.Container className={className}>
      {text.split("").map((item, index) => (
        <S.Light
          text={item}
          key={index}
          bottom={Math.abs(arrayText.length / 2 - index)}
          color={getRandomColor()}
        />
      ))}
    </S.Container>
  );
};

export default LightList;
