import React from "react";
import * as S from "./styled";

const CrashBox = () => (
  <S.Container id="box" class="box show-front">
    <S.Front class="front"></S.Front>
    <S.Back class="back"></S.Back>
    <S.Right class="right"></S.Right>
    <S.Left class="left"></S.Left>
    <S.Top class="top"></S.Top>
    <S.Bottom class="bottom"></S.Bottom>
  </S.Container>
);

export default CrashBox;
