import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import * as S from "./styled";

const Bank = ({ className }) => {
  return (
    <S.Container className={className}>
      <S.Header>
        <S.Title>
          Contact
          <S.ImgIntro src="images/rapture.png" />
        </S.Title>
      </S.Header>
      <S.Wrapper>
        <S.Item>
          <img src="images/varo.jpg" />
          <S.Content className="contactContent">
            <S.ContentWrapper className="contactContentWrapper">
              <S.ContentHeader>Álvaro</S.ContentHeader>
              <S.Link href="https://wa.me/+34699998443">
                +34 699 99 84 43
                <FaWhatsapp />
              </S.Link>
            </S.ContentWrapper>
            <S.Background src="images/simba.png" />
          </S.Content>
        </S.Item>
        <S.Item>
          <img src="images/diana2.jpg" />
          <S.Content className="contactContent">
            <S.ContentWrapper className="contactContentWrapper">
              <S.ContentHeader>Diana</S.ContentHeader>
              <S.Link href="https://wa.me/+34647536582">
                +34 64 75 36 582 <FaWhatsapp />
              </S.Link>
            </S.ContentWrapper>
            <S.Background src="images/wonderwoman.svg" />
          </S.Content>
        </S.Item>
      </S.Wrapper>
    </S.Container>
  );
};

export default Bank;
