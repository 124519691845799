import styled from "styled-components";

export const Container = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  border: solid 1px ${({ theme }) => theme.colors.black};
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: 3px 3px 3px #00000045;
  transition: all 0.3s;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
  &:hover {
    box-shadow: 6px 6px 6px #00000045;
    transform: translate(-1px, -1px);
  }
`;

export const ToastMessage = styled.div`
  position: relative;
`;
