import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./styled";

const Music = ({ className }) => {
  const { t } = useTranslation();
  return (
    <S.Container className={className}>
      <S.Vinyl className="vinyl">
        <S.Disc className="disc" />
        <S.DiscBox>
          <S.DiscImg src="images/freddie.png" />
        </S.DiscBox>
      </S.Vinyl>
      <S.Header>
        <S.Text>
          {t(
            "¿Cuál es la canción que no puede faltar en la playlistde la fista?"
          )}
        </S.Text>
        <S.Subtext>
          {t("(Mandanos tu canción y la añadiremos a esta lista)")}
        </S.Subtext>
      </S.Header>
      <S.Content>
        <iframe
          style={{ borderRadius: "12px", width: "100%" }}
          src="https://open.spotify.com/embed/playlist/1YykNWlRLxS7VsKtZIPViz?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </S.Content>
      <S.Footer></S.Footer>
    </S.Container>
  );
};

export default Music;
