import styled, { css } from "styled-components";

const buttonStyle = css`
  padding: 9px 16px;
  font-size: 21px;
  color: white;
  background-color: #f75252;
  display: inline-block;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  border: solid 1px transparent;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: translate(-3px, -3px);
    box-shadow: 3px 3px 4px #00000033;
  }
`;

export const Container = styled.button`
  ${buttonStyle}
`;

export const ContainerLink = styled.a`
  ${buttonStyle}
`;
