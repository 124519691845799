import React from "react";
import { useTranslation } from "react-i18next";
import Box from "components/atoms/crash-box";
import BankAccount from "components/atoms/bank-account";
import * as S from "./styled";

const Bank = ({ className }) => {
  const { t } = useTranslation();
  return (
    <S.Container className={className}>
      <S.Wrapper>
        <S.Wrapper size="md">
          <S.Header>
            <Box />
          </S.Header>
          <S.Content>
            {t(
              "El mejor regalo que nos puedes dar es tu asistencia, pero si quieres puedes aportar algo en esta cuenta"
            )}
          </S.Content>
        </S.Wrapper>
        <S.ContentButton>
          <BankAccount>ES61 0081 5234 1200 0165 0176</BankAccount>
        </S.ContentButton>
      </S.Wrapper>
    </S.Container>
  );
};

export default Bank;
