import React from "react";
import * as S from "./styled";

const Container = ({ children, className, size }) => (
  <S.Container className={className}>
    <S.Wrapper size={size}>{children}</S.Wrapper>
  </S.Container>
);

export default Container;
