import styled from "styled-components";
import ContainerComp from "components/atoms/container";

export const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  font-weight: lighter;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Wrapper = styled(ContainerComp)`
  position: relative;
  display: flex;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Header = styled(ContainerComp)`
  font-size: 61px;
  text-align: center;
  font-family: serif;
`;

export const ContentText = styled.p`
  font-weight: lighter;
  margin: 0px 0px;
  & strong {
    font-weight: 700;
  }
`;

export const Bottom = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  min-height: 239px;
  margin-top: 76px;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    margin-top: 0px;
  }
`;
export const ImageBottom = styled.img`
  position: absolute;
  z-index: 1;
  object-fit: contain;
  opacity: 0.3;
  width: 530px;
  margin-top: -35px;
  max-width: 90%;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    width: 311px;
    margin-top: 0px;
  }
`;
export const BottomText = styled.p`
  z-index: 2;
  font-family: handmade;
  font-size: 86px;
  font-weight: bold;
  @media (max-width: ${({ theme }) => theme.sizes.md}px) {
    font-size: 50px;
    margin-top: 21px;
  }
`;

export const Star = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
`;
