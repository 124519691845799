import React from "react";
import * as S from "./styled";
import Music from "components/molecules/music";

const Party = ({ className }) => {
  return (
    <S.Container className={className}>
      <S.Wrapper>
        <S.Header></S.Header>
        <S.Content>
          <Music />
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default Party;
