import React, { useState, Children } from "react";
import useResponsive from "hooks/useResponsive";
import Container from "components/atoms/container";
import * as S from "./styled";

const Book = ({ children }) => {
  const size = useResponsive();
  const count = Children.count(children) + 1;
  const [page, setPage] = useState(0);
  const onClickPrevPage = () => {
    if (page - 1 > 0) setPage(page - 2);
  };
  const onClickNextPage = () => {
    if (page + 1 < count) setPage(page + 2);
  };

  if (size === "xs" || size === "sm") {
    return (
      <Container size="sm">
        <S.SliderContainer>
          <S.Page count={count} className={` remove-animation`}>
            <S.BookImg src="images/book.jpg" />
            <S.NextPage count={count} onClick={onClickNextPage} />
          </S.Page>
          {children?.map((item, index) => {
            const indexPage = index + 1;
            const isSelected = page > indexPage;
            return (
              <S.Page
                className={`${isSelected && "selected"} remove-animation`}
                page={indexPage}
                count={count}
                key={indexPage}
              >
                {item}
              </S.Page>
            );
          })}
        </S.SliderContainer>
      </Container>
    );
  }

  return (
    <S.Container>
      <S.Wrapper>
        <S.Page
          page={0}
          count={count}
          selected={page > 0}
          className={page > 0 && "selected"}
          lastPage
          onClick={onClickNextPage}
        >
          <S.BookImg src="images/book.jpg" />
        </S.Page>
        {children?.map((item, index) => {
          const indexPage = index + 1;
          const isSelected = page > indexPage;
          return (
            <S.Page
              className={isSelected && "selected"}
              page={indexPage}
              count={count}
              key={indexPage}
              selected={isSelected}
            >
              {item}
              {index + 1 < children.length && (
                <S.NextPage
                  count={count}
                  onClick={
                    indexPage % 2 === 0 ? onClickNextPage : onClickPrevPage
                  }
                />
              )}
            </S.Page>
          );
        })}
        <S.Page page={count} count={count} lastPage>
          <S.LastPage>
            <S.Stiker1 src="images/bellaybestia.png" />
            <S.Stiker2 src="images/rapture.png" />
          </S.LastPage>
        </S.Page>
      </S.Wrapper>
    </S.Container>
  );
};

export default Book;
